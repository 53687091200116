import { colorsType } from "../interfaces/types";
import { getThemeColors } from "../utils/getThemeColors";

export const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Example usage:
const storedTheme = localStorage.getItem("theme") ?? "theme-appurs";
export const themeColors: colorsType = getThemeColors(storedTheme);

export const themeIcon = `${process.env.PUBLIC_URL}/${storedTheme}`;

export const latinicToCyrillicMap = {
    a: "а",
    b: "б",
    c: "ц",
    č: "ц",
    ć: "ц",
    d: "д",
    đ: "ђ",
    e: "е",
    f: "ф",
    g: "г",
    h: "х",
    i: "и",
    j: "ј",
    k: "к",
    l: "л",
    lj: "љ",
    m: "м",
    n: "н",
    nj: "њ",
    o: "о",
    p: "п",
    r: "р",
    s: "с",
    š: "с",
    t: "т",
    u: "у",
    v: "в",
    z: "з",
    ž: "з",
    dz: "џ",
    dž: "џ",
};

export const cyrillicToLatinicMap = {
    а: "a",
    б: "b",
    ц: "c",
    ч: "c",
    ћ: "c",
    д: "d",
    ђ: "dj",
    е: "e",
    ф: "f",
    г: "g",
    х: "h",
    и: "i",
    ј: "j",
    к: "k",
    л: "l",
    љ: "lj",
    м: "m",
    н: "n",
    њ: "nj",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    ш: "s",
    т: "t",
    у: "u",
    в: "v",
    з: "z",
    ж: "z",
    џ: "dz",
};

export const latinicToCyrillicMapExact = {
    a: "а",
    A: "А",
    b: "б",
    B: "Б",
    c: "ц",
    C: "Ц",
    č: "ч",
    Č: "Ч",
    ć: "ћ",
    Ć: "Ћ",
    d: "д",
    D: "Д",
    đ: "ђ",
    Đ: "Ђ",
    e: "е",
    E: "Е",
    f: "ф",
    F: "Ф",
    g: "г",
    G: "Г",
    h: "х",
    H: "Х",
    i: "и",
    I: "И",
    j: "ј",
    J: "Ј",
    k: "к",
    K: "К",
    l: "л",
    L: "Л",
    lj: "љ",
    Lj: "Љ",
    m: "м",
    M: "М",
    n: "н",
    N: "Н",
    nj: "њ",
    Nj: "Њ",
    o: "о",
    O: "О",
    p: "п",
    P: "П",
    r: "р",
    R: "Р",
    s: "с",
    S: "С",
    š: "ш",
    Š: "Ш",
    t: "т",
    T: "Т",
    u: "у",
    U: "У",
    v: "в",
    V: "В",
    z: "з",
    Z: "З",
    ž: "ж",
    Ž: "Ж",
    dz: "џ",
    Dz: "Џ",
    dž: "џ",
    Dž: "Џ",
};

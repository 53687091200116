import React, { useEffect } from "react";
import KatakomInput from "../../../shared/KatakomInput";
import KatakomButton from "../../../shared/KatakomButton";
import styled from "styled-components";
import { KatastarCard } from "../style";
import { useSidebar } from "../../../widgets/sidebar/SidebarContext";
import { CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper, Row } from "../../style";
import { RenderLicaData } from "../renderKatastarData/RenderLicaData";
import api from "../../../../services/api";
import useMobileCheck from "../../../../hooks/useMobileChecker";
import { VrstaLica } from "../../../../store/CaseReducer";
import { AplicantAdvokati, addOrUpdateUcesnikAdvokati } from "../../../../store/CaseAdvokatiReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { katastarskeOpstineOptions } from "../../../../register/register";
import { RowWrapper } from "../pretragaPoImenu/PretragaPoImenuLayout";
import { isJmbgOrPibValid } from "../../../../utils/utils";
import { backendUrl, themeColors } from "../../../../constants/constants";
import Cookies from "js-cookie";
import axios from "axios";
import { setUser } from "../../../../store/UserReducer";
import { RootState } from "../../../../store";

const PretragaPoMaticnomLayout: React.FC = () => {
    const [JMBG, setJMBG] = React.useState<string>("");
    const [data, setData] = React.useState<any>(null);
    const [detrimendHeight, setDetrimendHeight] = React.useState<string>("calc(100vh - 120px)");
    const isMobile = useMobileCheck();
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [tooltipMessage, setTooltipMessage] = React.useState<string>("");
    const [vrstaLica, setVrstaLica] = React.useState<string>("Fizicko");
    const user = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    };

    useEffect(() => {
        if (data?.Lica?.Lice) {
            setDetrimendHeight("20vh");
        } else {
            setDetrimendHeight("calc(100vh - 120px)");
        }
    }, [data]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value === "" || (/^\d+$/.test(value) && value.length <= 13)) {
            setJMBG(value);
        }
    };

    const handleVrstaLicaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVrstaLica(event.target.value);
    };

    const fatchData = async () => {
        const res = await api.get(`api/v1/pretraga-lica/?jmbg=${JMBG}`);
        if (res?.data?.Lica?.Lice?.length === 0) {
            setData(null);
            return;
        }

        setData({
            ...res?.data,
        });

        const u = {
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            email: user.email,
            theme: user.theme,
            brojPretraga: user.brojPretraga + 1,
        };

        dispatch(setUser(u));
    };

    const { sidebar } = useSidebar();

    const handleStartCase = async () => {
        const d = data?.Lica.Lice;
        const mesto = d.Adresa.split(",")[0];
        const ulica = d.Adresa.split(",")[1];
        const opstina = katastarskeOpstineOptions.find((item) => item.label.includes(`${d.Mesto}, ${mesto}`));
        const opstinaIds = opstina?.value.split("_");
        const opstinaId = opstinaIds?.[2];
        const koId = opstinaIds?.[3];
        const podnosioc: AplicantAdvokati = {
            vrstaLica: VrstaLica.FIZICKO_DOMACE,
            ime: d.Ime,
            prezime: d.Prezime,
            imeRoditelja: d.ImeRoditelja,
            jmbg: d.MaticniBroj,
            opstinaSifra: opstinaId,
            mestoSifra: koId,
            ulicaRucno: ulica,
        };

        dispatch(addOrUpdateUcesnikAdvokati(podnosioc));
        routeChange("/noviPredmetAdvokati");
    };

    const isDisabled = (): boolean => {
        if (JMBG.length === 0) {
            setTooltipMessage("Матични број је обавезан.");
            return true;
        }

        if (vrstaLica === "Fizicko" && JMBG.length !== 13) {
            setTooltipMessage("ЈМБГ мора да садржи 13 цифара за физичко лице.");
            return true;
        }

        if (vrstaLica === "Pravno" && ![7, 8, 9].includes(JMBG.length)) {
            setTooltipMessage("ЈМБГ мора да садржи 7, 8 или 9 цифара за правно лице.");
            return true;
        }

        setTooltipMessage("");
        return false;
    };

    useEffect(() => {
        setDisabled(isDisabled());
    }, [JMBG, vrstaLica]);

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <KatastarCard height={detrimendHeight}>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>ПРЕТРАГА ПО МАТИЧНОМ БРОЈУ</CardTitle>
                        </CardHeader>
                        {isMobile ? (
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", margin: "auto" }}>
                                <Row>
                                    <KatakomInput
                                        type={"number"}
                                        value={JMBG}
                                        name={""}
                                        label="Матични број*"
                                        error={false}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                        onEnter={fatchData}
                                    />
                                </Row>

                                <Row>
                                    <div>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Fizicko"
                                                checked={vrstaLica === "Fizicko"}
                                                onChange={handleVrstaLicaChange}
                                            />
                                            Физичко лице
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Pravno"
                                                checked={vrstaLica === "Pravno"}
                                                onChange={handleVrstaLicaChange}
                                            />
                                            Правно лице
                                        </label>
                                    </div>
                                </Row>

                                <KatakomButton
                                    label={"ПРЕТРАЖИ"}
                                    onClick={fatchData}
                                    size="xl"
                                    style={{ margin: "auto", marginBottom: "20px" }}
                                    tooltipMessage={tooltipMessage}
                                    disabled={disabled}
                                />
                            </div>
                        ) : (
                            <RowWrapper style={{ marginTop: "20px" }}>
                                <KatakomInput
                                    type={"number"}
                                    value={JMBG}
                                    name={""}
                                    label="Матични број*"
                                    error={false}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                    onEnter={fatchData}
                                />
                                <KatakomButton
                                    label={"ПРЕТРАЖИ"}
                                    onClick={fatchData}
                                    style={{ marginTop: "40px" }}
                                    tooltipMessage={tooltipMessage}
                                    disabled={disabled}
                                />

                                <Row>
                                    <RadioContainer>
                                        <StyledLabel>
                                            <StyledRadio
                                                type="radio"
                                                value="Fizicko"
                                                checked={vrstaLica === "Fizicko"}
                                                onChange={handleVrstaLicaChange}
                                            />
                                            Физичко лице
                                        </StyledLabel>
                                        <StyledLabel>
                                            <StyledRadio
                                                type="radio"
                                                value="Pravno"
                                                checked={vrstaLica === "Pravno"}
                                                onChange={handleVrstaLicaChange}
                                            />
                                            Правно лице
                                        </StyledLabel>
                                    </RadioContainer>
                                </Row>
                            </RowWrapper>
                        )}

                        {data !== null && !data?.Lica.Lice ? <DataNotFound>*Нема података за унету вредност</DataNotFound> : null}
                    </CardContainer>
                </KatastarCard>
            </CardWrapper>

            {data?.Lica?.Lice ? <RenderLicaData data={data} /> : null}
        </LayoutWraper>
    );
};

const DataNotFound = styled.div`
    display: flex;
    margin: 20px 50px 0px 30px;
    font-size: 15px;
    font-weight: 600;
`;
const RadioContainer = styled.div`
    display: flex;
    gap: 20px; /* Adjust the gap between radio buttons */
    align-items: center;
    margin-top: 10px;
`;

const StyledLabel = styled.label`
    font-size: 16px;
    font-weight: 500;
    color: #333;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const StyledRadio = styled.input.attrs({ type: "radio" })`
    appearance: none;
    background-color: #fff;
    margin-right: 10px;
    font: inherit;
    color: ${themeColors.secondary};
    width: 18px;
    height: 18px;
    border: 2px solid ${themeColors.quaternary};
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;

    &::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em ${themeColors.secondary};
        background-color: ${themeColors.secondary};
    }

    &:checked::before {
        transform: scale(1);
    }

    &:checked {
        background-color: ${themeColors.secondary};
        border-color: ${themeColors.secondary};
    }
`;

export default PretragaPoMaticnomLayout;

import React, { useState } from "react";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../constants/constants";
import { useSidebar } from "../widgets/sidebar/SidebarContext";
import { CardWrapper, LayoutWraper } from "../pages/style";
import useMobileCheck from "../../hooks/useMobileChecker";
import { Chatbot } from "./Chatbot";

const Card = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${themeColors.primary};
    height: calc(100vh - 120px);
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: relative;
    width: 100%;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url(${themeIcon}/bckg.png);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;
        opacity: 1;
    }
`;

const LogoContainer = styled.div`
    margin-bottom: 0px;
    font-size: 42px;
    img {
        width: 120px;
    }
`;

const UserName = styled.div`
    font-size: 48px;
    color: ${themeColors.quaternary};
`;

const ChatbotButton = styled.div`
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;

    img {
        z-index: 1;
    }
`;

const WelcomeUser: React.FC = () => {
    const { sidebar } = useSidebar();
    const isMobile = useMobileCheck();
    const [showChatbot, setShowChatbot] = useState<boolean>(false);

    const toggleChatbot = () => {
        setShowChatbot(!showChatbot);
    };

    const turnOffChatbot = () => {
        setShowChatbot(false);
    };
    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <Card>
                    {isMobile ? null : (
                        <>
                            {/* <LogoContainer>
                                <img src={`${themeIcon}/profile.png`} alt="Welcome user" />
                            </LogoContainer> */}
                            {/* <UserName>Добро дошли!</UserName> */}
                        </>
                    )}
                    {!isMobile ? (
                        <React.Fragment>
                            <ChatbotButton onClick={toggleChatbot}>
                                <img
                                    src={showChatbot ? `${themeIcon}/closeChatbot.png` : `${themeIcon}/chatbot.png`}
                                    alt="Chatbot button"
                                />
                            </ChatbotButton>
                            {showChatbot && <Chatbot turnOffChatbot={turnOffChatbot} />}
                        </React.Fragment>
                    ) : null}
                </Card>
            </CardWrapper>
        </LayoutWraper>
    );
};

export default WelcomeUser;

import React, { FormEvent, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { backendUrl, themeColors, themeIcon } from "../../../constants/constants";
import useKatakomInput from "../../../hooks/useKatakomInput";
import KatakomInput from "../../shared/KatakomInput";
import KatakomButton from "../../shared/KatakomButton";
import styled from "styled-components";
import { USER_NOT_ACTIVE } from "../../../constants/Error.constants";
import { INVALID_USER_NAME_OR_PASSWORD } from "../../../constants/Error.constants";
import { Row } from "../style";
import KatakomModal from "../../shared/KatakomModal";
import { ResetPasswordModal } from "./ResetPasswordModal";
import { redirect } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { changeRole, setUser } from "../../../store/UserReducer";

import useMobileCheck from "../../../hooks/useMobileChecker";

const LoginContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    background-color: ${themeColors.primary};
    background-image: url(${themeIcon}/appurslogin.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;


const LoginCard = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    width: 500px;
    height: 400px;
    box-shadow: 5px 5px 10px 0px #00000040;
    background-color: #ffffffb0;
    padding: 30px 0 50px 0;
    margin-bottom: 12vh;
`;

const LoginForm = styled.div`
    width: 330px;
`;

const LoginButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ForgotPasswordButton = styled.button`
    outline: none;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

const LogoContainer = styled.div`
    img {
        width: 100px;
    }
`;

const TwoFaLogoContainer = styled.div`
    img {
        width: 100px;
    }
`;

const WrongCredentials = styled.div`
    font-size: 16px;
    font-weight: 700;
    height: 35px;
    margin-top: 14px;
`;

const LoginPage: React.FC = () => {
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);
    const [twoFaRequired, setTwoFaRequired] = useState<boolean>(false);
    const isMobile = useMobileCheck();

    const dispatch = useAppDispatch();

    const email = useKatakomInput("");
    const password = useKatakomInput("");
    const code = useKatakomInput("");

    const isValid = () => {
        if (!email.value.includes("@")) {
            return false;
        }
        if (password.value.length === 0) {
            return false;
        }
        return true;
    };

    const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
        if (e) e.preventDefault();
        setErrorMessage("");

        if (isValid()) {
            try {
                await handleLogin();
            } catch (err: any) {
                if (err.response?.data?.code === INVALID_USER_NAME_OR_PASSWORD.code) {
                    setShowError(true);
                    setErrorMessage("Погрешни креденцијали!");
                } else if (err.response?.data?.code === USER_NOT_ACTIVE.code) {
                    setShowError(true);
                    setErrorMessage("Корисник је деактивиран!");
                }
            }
        }
    };

    const handleLogin = async () => {
        const response = await axios.post(`${backendUrl}login`, {
            email: email.value,
            password: password.value,
        });

        //@ts-ignore
        if (response.data.is2faEnabled) {
            setTwoFaRequired(true);
        } else {
            setUserData(response);
        }
    };

    const handleTwoFaLogin = async () => {
        const response = await axios.post(`${backendUrl}two-fa-login`, {
            email: email.value,
            password: password.value,
            code: code.value,
        });

        setUserData(response);
    };

    const setUserData = async (response: any) => {
        if (response.status === 200) {
            Cookies.set("accessToken", response.data.accessToken, {
                expires: 1 / 24,
                secure: false,
            });

            const headers = { Authorization: `${Cookies.get("accessToken")}` };

            const userSettings = await axios.get(`${backendUrl}api/v1/get-user?email=${email.value}`, {
                headers,
            });

            const role = userSettings.data.type;
            const u = {
                firstName: userSettings.data.first_name,
                lastName: userSettings.data.last_name,
                role: userSettings.data.type,
                email: userSettings.data.email,
                theme: userSettings.data.theme,
                brojPretraga: userSettings.data.broj_pretraga,
            };

            dispatch(changeRole(role));
            dispatch(setUser(u));

            if (userSettings.data.theme_id === "1" || !userSettings.data?.theme_id) {
                localStorage.setItem("theme", "theme-appurs");
            } else {
                localStorage.setItem("theme", userSettings.data?.theme_id);
            }

            if (userSettings.data.auto_colapse || userSettings.data.auto_colapse === undefined || userSettings.data.auto_colapse === null) {
                localStorage.setItem("sidebarAutoCollaps", "1");
            } else {
                localStorage.setItem("sidebarAutoCollaps", "0");
            }

            localStorage.setItem("email", email.value);

            redirect("/pocetna");
            window.location.reload();
        }
    };

    return (
        <LoginContainer>
            <KatakomModal isOpen={resetPasswordModal} setIsOpen={setResetPasswordModal}>
                <ResetPasswordModal
                    closeModal={() => {
                        setResetPasswordModal(false);
                    }}
                />
            </KatakomModal>
            <LoginCard>
                {twoFaRequired ? (
                    <TwoFaLogoContainer>
                        <img src={`${process.env.PUBLIC_URL}/twoFaLogo.png`} alt="Logo" />
                    </TwoFaLogoContainer>
                ) : (
                    <LogoContainer>
                        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="2FA logo" />
                    </LogoContainer>
                )}
                <LoginForm>
                    {twoFaRequired ? (
                        <Row>
                            <KatakomInput type={"number"} name={"code"} placeholder="Код" size="l" {...code} onEnter={handleSubmit} />
                        </Row>
                    ) : (
                        <>
                            <Row>
                                <KatakomInput
                                    type={"text"}
                                    name={"email"}
                                    placeholder="Е-пошта"
                                    size="l"
                                    {...email}
                                    onEnter={handleSubmit}
                                />
                            </Row>
                            <Row>
                                <KatakomInput
                                    type={"password"}
                                    name={"password"}
                                    placeholder="Лозинка"
                                    size="l"
                                    {...password}
                                    onEnter={handleSubmit}
                                />
                            </Row>
                        </>
                    )}
                    {showError ? <WrongCredentials>{errorMessage}</WrongCredentials> : <WrongCredentials></WrongCredentials>}
                    {twoFaRequired ? (
                        <LoginButtons>
                            <KatakomButton
                                style={{ background: themeColors.secondary, fontSize: isMobile ? "14px" : "16px" }}
                                label={"Пријавите се"}
                                size="m"
                                onClick={handleTwoFaLogin}
                                key={"twoFaLoginButton"}
                            />
                            <KatakomButton
                                style={{ fontSize: isMobile ? "14px" : "16px" }}
                                label={"Одустани"}
                                size="m"
                                theme="secondary"
                                onClick={() => {
                                    setTwoFaRequired(false);
                                }}
                            />
                        </LoginButtons>
                    ) : (
                        <LoginButtons>
                            <KatakomButton
                                style={{ background: themeColors.secondary, fontSize: isMobile ? "14px" : "16px" }}
                                label={"Пријавите се"}
                                size="m"
                                onClick={handleSubmit}
                                key={"loginButton"}
                            />
                            <ForgotPasswordButton
                                onClick={() => {
                                    setResetPasswordModal(true);
                                }}
                            >
                                Заборавили сте шифру?
                            </ForgotPasswordButton>
                        </LoginButtons>
                    )}
                </LoginForm>
            </LoginCard>
        </LoginContainer>
    );
};

export default LoginPage;

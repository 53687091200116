import { ChangeEvent } from "react";
import { katastarskeOpstineOptions } from "../../../../../../register/register";
import KatakomInput from "../../../../../shared/KatakomInput";
import KatakomSelect, { SelectOption } from "../../../../../shared/KatakomSelect";
import { Checkbox, Row } from "../../../../style";
import { AplicantTip2 } from "../../../../../../store/CaseTip2Reducer";
import { CheckboxLabel } from "../AplicantsModalComponentTip2";

export const PravnoDomace = ({ formData, setFormData }: { formData: AplicantTip2; setFormData: (aplicant: AplicantTip2) => void }) => {
    return (
        <>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.imePravnogLica || ""}
                    name="imePravnogLica"
                    label="Име правног лица*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, imePravnogLica: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="number"
                    value={formData.maticniBroj || ""}
                    name="maticniBroj"
                    label="Матични број*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, maticniBroj: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="number"
                    value={formData.pib || ""}
                    name="pib"
                    label="ПИБ"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, pib: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.email || ""}
                    name="email"
                    label="Е-пошта"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, email: e.target.value })}
                />
            </Row>
            <Row>
                <KatakomSelect
                    options={katastarskeOpstineOptions}
                    value={formData.mestoSifra || ""}
                    name="mestoSifra"
                    size="s"
                    label="Општина*"
                    error={false}
                    onChange={(option: SelectOption) => {
                        const opstinaNaziv = option.value.split("_")[0];
                        const mestoNaziv = option.value.split("_")[1];
                        const opstinaSifra = option.value.split("_")[2];
                        const mestoSifra = option.value.split("_")[3];
                        setFormData({ ...formData, opstinaNaziv, mestoNaziv, opstinaSifra, mestoSifra });
                    }}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.ulicaRucno || ""}
                    name="ulicaRucno"
                    label="Улица*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, ulicaRucno: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.kucniBroj || ""}
                    name="kucniBroj"
                    label="Кућни број*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, kucniBroj: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.postanskiBroj || ""}
                    name="postanskiBroj"
                    label="Поштански број"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, postanskiBroj: e.target.value })}
                />
            </Row>
            <Row>
                <CheckboxLabel>
                    Косово и метохија:
                    <Checkbox
                        checked={formData.flagKIM || false}
                        onChange={(e) => setFormData({ ...formData, flagKIM: e.target.checked })}
                    />
                </CheckboxLabel>
            </Row>
        </>
    );
};

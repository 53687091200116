import React, { useEffect, SetStateAction } from "react";
import KatakomInput from "../../../shared/KatakomInput";
import KatakomButton from "../../../shared/KatakomButton";
import styled from "styled-components";
import { KatastarCard } from "../style";
import { useSidebar } from "../../../widgets/sidebar/SidebarContext";
import { CardContainer, CardHeader, CardTitle, CardWrapper, KatakomPaginate, LayoutWraper, Row } from "../../style";
import api from "../../../../services/api";
import useMobileCheck from "../../../../hooks/useMobileChecker";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mestaOptions } from "../../../../register/register";
import KatakomSelect, { SelectOption } from "../../../shared/KatakomSelect";
import { RenderLicaCards } from "./RenderLicaCards";
import { themeColors } from "../../../../constants/constants";
import { KatakomInfoBox } from "../../../shared/KatakomInfoBox";

const PretragaPoImenuLayout: React.FC = () => {
    const [vrstaLica, setVrstaLica] = React.useState<string>("Fizicko");
    const [ime, setIme] = React.useState<string>("");
    const [prezime, setPrezime] = React.useState<string>("");
    const [naziv, setNaziv] = React.useState<string>(""); // For legal entity name
    const [data, setData] = React.useState<any>(null);
    const [mesto, setMesto] = React.useState<string>("");
    const [detrimendHeight, setDetrimendHeight] = React.useState<string>("calc(100vh - 120px)");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [totalPages, setTotalPages] = React.useState(0);
    const [disabled, setDisabled] = React.useState(false);
    const [tooltipMessage, setTooltipMessage] = React.useState("");
    const [openedCard, setOpenedCard] = React.useState(false);
    const isMobile = useMobileCheck();
    const { sidebar } = useSidebar();
    const [liceNepokretnostiData, setLiceNepokretnostiData] = React.useState(null);
    const itemsPerPage = 10;

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    };

    useEffect(() => {
        if (vrstaLica === "Fizicko") {
            const isCyrillic = /^[\u0400-\u04FF\s]*$/.test(ime) && /^[\u0400-\u04FF\s]*$/.test(prezime);
            if (!isCyrillic || !ime || !prezime) {
                setDisabled(true);
                setTooltipMessage("Морате унети име и презиме (ћирилицом)");
            } else {
                setDisabled(false);
                setTooltipMessage("");
            }
        } else if (vrstaLica === "Pravno") {
            const isCyrillic = /^[\u0400-\u04FF\s]*$/.test(naziv);
            if (!isCyrillic || !naziv) {
                setDisabled(true);
                setTooltipMessage("Морате унети назив компаније (ћирилицом)");
            } else {
                setDisabled(false);
                setTooltipMessage("");
            }
        }
    }, [ime, prezime, naziv, vrstaLica]);

    const fatchData = async (offset: number) => {
        setLoading(true);

        let endpoint = `api/v1/pretraga-lica-po-imenu`;

        let queryParams: any = {
            limit: itemsPerPage,
            offset,
        };

        if (vrstaLica === "Fizicko") {
            if (mesto) queryParams.mesto = mesto;
            if (ime) queryParams.ime = ime;
            if (prezime) queryParams.prezime = prezime;
        } else if (vrstaLica === "Pravno") {
            if (naziv) queryParams.prezime = naziv;
            if (mesto) queryParams.mesto = mesto;
        }

        const res = await api.get(endpoint, queryParams);

        if (res?.data) {
            setData(res?.data?.content);
            setTotalPages(res.data.totalPages);
            setCurrentPage(res.data.pageable.pageNumber);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (data?.length !== 0 && ((vrstaLica === "Fizicko" && ime && prezime) || (vrstaLica === "Pravno" && naziv))) {
            const currentPageNumber = Math.floor(currentPage * itemsPerPage);
            fatchData(currentPageNumber);
        }
    }, [currentPage]);

    const handleSearchClick = () => {
        fatchData(currentPage);
        setCurrentPage(0);
    };

    const handlePageClick = (selected: { selected: SetStateAction<number> }) => {
        setCurrentPage(selected.selected);
    };

    const handleVrstaLicaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVrstaLica(event.target.value);
        setIme("");
        setPrezime("");
        setNaziv("");
    };

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <KatastarCard height={detrimendHeight}>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>ПРЕТРАГА ПО ИМЕНУ</CardTitle>
                        </CardHeader>
                        {isMobile ? (
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", margin: "auto" }}>
                                <Row>
                                    <div>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Fizicko"
                                                checked={vrstaLica === "Fizicko"}
                                                onChange={handleVrstaLicaChange}
                                            />
                                            Физичко лице
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Pravno"
                                                checked={vrstaLica === "Pravno"}
                                                onChange={handleVrstaLicaChange}
                                            />
                                            Правно лице
                                        </label>
                                    </div>
                                </Row>

                                {vrstaLica === "Fizicko" ? (
                                    <>
                                        <Row>
                                            <KatakomInput
                                                type={"text"}
                                                value={ime}
                                                name={""}
                                                label="Име*"
                                                error={false}
                                                size="s"
                                                onChange={(e) => {
                                                    setIme(e.target.value);
                                                }}
                                                onEnter={handleSearchClick}
                                            />
                                        </Row>

                                        <Row>
                                            <KatakomInput
                                                type={"text"}
                                                value={prezime}
                                                name={""}
                                                label="Презиме*"
                                                size="s"
                                                error={false}
                                                onChange={(e) => {
                                                    setPrezime(e.target.value);
                                                }}
                                                onEnter={handleSearchClick}
                                            />
                                        </Row>
                                    </>
                                ) : (
                                    <Row>
                                        <KatakomInput
                                            type={"text"}
                                            value={naziv}
                                            name={""}
                                            label="Назив компаније*"
                                            error={false}
                                            size="l"
                                            onChange={(e) => {
                                                setNaziv(e.target.value);
                                            }}
                                            onEnter={handleSearchClick}
                                        />
                                    </Row>
                                )}

                                <Row>
                                    <KatakomSelect
                                        options={mestaOptions}
                                        value={mesto}
                                        name="mesto"
                                        size="m"
                                        label="Општина - Катастарска општина"
                                        error={false}
                                        onChange={(option: SelectOption) => {
                                            setMesto(option.value);
                                        }}
                                    />
                                </Row>

                                <Row>
                                    <KatakomButton
                                        label={"ПРЕТРАЖИ"}
                                        onClick={handleSearchClick}
                                        size="xl"
                                        style={{ margin: "auto", marginBottom: "20px" }}
                                        disabled={disabled}
                                        tooltipMessage={tooltipMessage}
                                    />
                                </Row>

                                {openedCard ? (
                                    <Row>
                                        <KatakomButton
                                            label={"Назад"}
                                            onClick={() => {
                                                setLiceNepokretnostiData(null);
                                                setOpenedCard(false);
                                            }}
                                            style={{ marginTop: "40px" }}
                                        />
                                    </Row>
                                ) : null}
                            </div>
                        ) : (
                            <RowWrapper style={{ marginTop: "20px" }}>
                                <Row>
                                    <StyledLabel>
                                        <StyledRadio
                                            type="radio"
                                            value="Fizicko"
                                            checked={vrstaLica === "Fizicko"}
                                            onChange={handleVrstaLicaChange}
                                        />
                                        Физичко лице
                                    </StyledLabel>
                                    <StyledLabel>
                                        <StyledRadio
                                            type="radio"
                                            value="Pravno"
                                            checked={vrstaLica === "Pravno"}
                                            onChange={handleVrstaLicaChange}
                                        />
                                        Правно лице
                                    </StyledLabel>
                                </Row>

                                {vrstaLica === "Fizicko" ? (
                                    <>
                                        <KatakomInput
                                            type={"text"}
                                            value={ime}
                                            name={""}
                                            label="Име*"
                                            error={false}
                                            size="s"
                                            onChange={(e) => {
                                                setIme(e.target.value);
                                            }}
                                            onEnter={handleSearchClick}
                                        />
                                        <KatakomInput
                                            type={"text"}
                                            value={prezime}
                                            name={""}
                                            label="Презиме*"
                                            error={false}
                                            size="s"
                                            onChange={(e) => {
                                                setPrezime(e.target.value);
                                            }}
                                            onEnter={handleSearchClick}
                                        />
                                    </>
                                ) : (
                                    <KatakomInput
                                        type={"text"}
                                        value={naziv}
                                        name={""}
                                        label="Назив компаније*"
                                        error={false}
                                        size="l"
                                        onChange={(e) => {
                                            setNaziv(e.target.value);
                                        }}
                                        onEnter={handleSearchClick}
                                    />
                                )}

                                <KatakomSelect
                                    options={mestaOptions}
                                    value={mesto}
                                    name="mesto"
                                    size="m"
                                    label="Општина - Катастарска општина"
                                    error={false}
                                    onChange={(option: SelectOption) => {
                                        setMesto(option.value);
                                    }}
                                />
                                <KatakomButton
                                    label={"ПРЕТРАЖИ"}
                                    onClick={handleSearchClick}
                                    disabled={disabled}
                                    style={{ marginTop: "40px" }}
                                    tooltipMessage={tooltipMessage}
                                />
                                {openedCard ? (
                                    <KatakomButton
                                        label={"Назад"}
                                        onClick={() => {
                                            setLiceNepokretnostiData(null);
                                            setOpenedCard(false);
                                        }}
                                        style={{ marginTop: "40px" }}
                                        theme="secondary"
                                    />
                                ) : null}
                            </RowWrapper>
                        )}
                        {openedCard ? null : <KatakomInfoBox text="Обавезно је користити ћирилична слова за претрагу!" />}

                        {loading ? (
                            <LoaderContainer>
                                <Loader />
                            </LoaderContainer>
                        ) : (
                            <>
                                {data && (
                                    <RenderLicaCards
                                        data={data}
                                        setOpenedCard={setOpenedCard}
                                        setLiceNepokretnostiData={setLiceNepokretnostiData}
                                        liceNepokretnostiData={liceNepokretnostiData}
                                    />
                                )}
                            </>
                        )}
                        {data?.length > 0 && !openedCard && (
                            <KatakomPaginate
                                previousLabel={isMobile ? "<<" : "Претходно"}
                                nextLabel={isMobile ? ">>" : `Следеће`}
                                breakLabel={"..."}
                                pageCount={totalPages}
                                marginPagesDisplayed={isMobile ? 1 : 2}
                                pageRangeDisplayed={isMobile ? 1 : 5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        )}
                    </CardContainer>
                </KatastarCard>
            </CardWrapper>
        </LayoutWraper>
    );
};

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
`;

const Loader = styled.div`
    border: 4px solid #f3f3f3;
    border-top: 4px solid ${themeColors.quaternary};
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const StyledLabel = styled.label`
    font-size: 16px;
    font-weight: 500;
    color: #333;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const StyledRadio = styled.input.attrs({ type: "radio" })`
    appearance: none;
    background-color: #fff;
    margin-right: 10px;
    font: inherit;
    color: ${themeColors.secondary};
    width: 18px;
    height: 18px;
    border: 2px solid ${themeColors.quaternary};
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;

    &::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em ${themeColors.secondary};
        background-color: ${themeColors.secondary};
    }

    &:checked::before {
        transform: scale(1);
    }

    &:checked {
        background-color: ${themeColors.secondary};
        border-color: ${themeColors.secondary};
    }
`;

export const RowWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px;
    margin-left: 20px;
    margin-top: 50px;
    margin-bottom: 40px;
`;

export default PretragaPoImenuLayout;

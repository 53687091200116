import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomButton from "../../../../shared/KatakomButton";
import { AplicantTip2, VrstaLica, addOrUpdateUcesnikTip2 } from "../../../../../store/CaseTip2Reducer";
import { themeColors } from "../../../../../constants/constants";
import { defaultAplicantTip2 } from "../../../../../register/defaultValues";
import { Checkbox, ModalContent, Row } from "../../../style";
import { PravnoDomace } from "./aplicants/PravnoDomace";
import { PravnoStranac } from "./aplicants/PravnoStranac";
import { FizickoDomace } from "./aplicants/FizickoDomace";
import { FizickoStranac } from "./aplicants/FizickoStranac";
import { RepublikaSrpska } from "./aplicants/RepublikaSrpska";
import { isBrojLicneKarteValid, isEmailValid, isJmbgValid } from "../../../../../utils/utils";

import useMobileCheck from "../../../../../hooks/useMobileChecker";

interface Props {
    closeModal: () => void;
    ucesnikId?: number;
    setEditingUcesnik: (ucesnik: AplicantTip2 | null) => void;
    modalState: boolean;
}

export const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 400;
    color: ${themeColors.secondary};
`;

const AplicantsModalComponentTip2: FC<Props> = ({ closeModal, ucesnikId, setEditingUcesnik, modalState }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const ucesnici = useSelector((state: RootState) => state.caseTip2.ucesnici);

    const [formData, setFormData] = useState<AplicantTip2>(defaultAplicantTip2);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [firstTimeEdit, setFirstTimeEdit] = useState<boolean>(true);
    const [tooltipMessage, setTooltipMessage] = useState<string>("");
    const [secondTimeEdit, setSecondTimeEdit] = useState<boolean>(true);
    const isMobile = useMobileCheck();

    useEffect(() => {
        if (ucesnikId !== undefined) {
            const existingUcesnik = ucesnici.find((pod) => pod.id === ucesnikId);
            if (existingUcesnik) {
                setFormData(existingUcesnik);
            }
        } else {
            setFormData(defaultAplicantTip2);
        }
    }, [ucesnici, ucesnikId]);

    useEffect(() => {
        if ((ucesnikId === undefined && !firstTimeEdit) || (ucesnikId !== undefined && !firstTimeEdit)) {
            setFormData({ ...defaultAplicantTip2, vrstaLica: formData.vrstaLica, id: ucesnikId });
        }
        if (firstTimeEdit && formData.vrstaLica !== VrstaLica.FIZICKO_DOMACE) {
            setFirstTimeEdit(false);
        }
    }, [formData.vrstaLica]);

    useEffect(() => {
        setDisabled(isDisabled());
    }, [ucesnici, formData]);

    const handleSave = () => {
        dispatch(addOrUpdateUcesnikTip2(formData));
        setEditingUcesnik(null);
        closeModal();
    };

    const handleClose = () => {
        setEditingUcesnik(null);
        closeModal();
    };

    const isDisabled = (): boolean => {
        if (Object.values(formData).length === 0) {
            setTooltipMessage("");
            return true;
        }
        if (
            formData.vrstaLica === VrstaLica.FIZICKO_DOMACE &&
            (!formData.ime || !formData.prezime || !formData.jmbg || !formData.opstinaNaziv || !formData.kucniBroj || !formData.ulicaRucno)
        ) {
            setTooltipMessage("");
            return true;
        }
        if (
            formData.vrstaLica === VrstaLica.FIZICKO_STRANAC &&
            (!formData.ime ||
                !formData.prezime ||
                !formData.imeRoditelja ||
                !formData.mestoNaziv ||
                !formData.drzavaNaziv ||
                !formData.brojPasosa ||
                !formData.kucniBroj ||
                !formData.ulicaRucno)
        ) {
            setTooltipMessage("");
            return true;
        }
        if (
            formData.vrstaLica === VrstaLica.PRAVNO_DOMACE &&
            (!formData.imePravnogLica || !formData.maticniBroj || !formData.opstinaNaziv || !formData.kucniBroj || !formData.ulicaRucno)
        ) {
            setTooltipMessage("");
            return true;
        }
        if (
            formData.vrstaLica === VrstaLica.PRAVNO_STRANAC &&
            (!formData.imePravnogLica ||
                !formData.brojUpisaURegistarDrzaveSedista ||
                !formData.nazivRegistra ||
                !formData.mestoNaziv ||
                !formData.drzavaNaziv ||
                !formData.kucniBroj ||
                !formData.ulicaRucno)
        ) {
            setTooltipMessage("");
            return true;
        }

        if (!!formData.email && !isEmailValid(formData.email)) {
            setTooltipMessage("Е-пошта није у исправном формату.");
            return true;
        }

        if (!!formData.jmbg && !isJmbgValid(formData.jmbg)) {
            setTooltipMessage("ЈМБГ мора да садржи 13 цифара.");
            return true;
        }

        if (!!formData.brojLicneKarte && !isBrojLicneKarteValid(formData.brojLicneKarte)) {
            setTooltipMessage("Број личне карте мора да садржи 9 цифара.");
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (modalState && !isMobile) {
            const select = document.getElementById("AplicantModalComponentTip2");
            select?.focus();
        }
    }, [modalState]);

    return (
        <ModalContent id="AplicantModalComponentTip2" tabIndex={0}>
            <Row>
                <CheckboxLabel>
                    Физичко домаће:
                    <Checkbox
                        checked={formData.vrstaLica === VrstaLica.FIZICKO_DOMACE}
                        onChange={(e) => {
                            setFormData({ ...formData, vrstaLica: VrstaLica.FIZICKO_DOMACE });
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                setFormData({ ...formData, vrstaLica: VrstaLica.FIZICKO_DOMACE });
                            }
                        }}
                    />
                </CheckboxLabel>
                <CheckboxLabel>
                    Физичко странац:
                    <Checkbox
                        checked={formData.vrstaLica === VrstaLica.FIZICKO_STRANAC}
                        onChange={(e) => {
                            setFormData({ ...formData, vrstaLica: VrstaLica.FIZICKO_STRANAC });
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                setFormData({ ...formData, vrstaLica: VrstaLica.FIZICKO_STRANAC });
                            }
                        }}
                    />
                </CheckboxLabel>
                <CheckboxLabel>
                    Правно домаће:
                    <Checkbox
                        checked={formData.vrstaLica === VrstaLica.PRAVNO_DOMACE}
                        onChange={(e) => {
                            setFormData({ ...formData, vrstaLica: VrstaLica.PRAVNO_DOMACE });
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                setFormData({ ...formData, vrstaLica: VrstaLica.PRAVNO_DOMACE });
                            }
                        }}
                    />
                </CheckboxLabel>
                <CheckboxLabel>
                    Правно странац:
                    <Checkbox
                        checked={formData.vrstaLica === VrstaLica.PRAVNO_STRANAC}
                        onChange={(e) => {
                            setFormData({ ...formData, vrstaLica: VrstaLica.PRAVNO_STRANAC });
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                setFormData({ ...formData, vrstaLica: VrstaLica.PRAVNO_STRANAC });
                            }
                        }}
                    />
                </CheckboxLabel>
            </Row>

            {formData.vrstaLica === VrstaLica.PRAVNO_DOMACE ? (
                <PravnoDomace formData={formData} setFormData={setFormData} />
            ) : formData.vrstaLica === VrstaLica.PRAVNO_STRANAC ? (
                <PravnoStranac formData={formData} setFormData={setFormData} />
            ) : formData.vrstaLica === VrstaLica.FIZICKO_DOMACE ? (
                <FizickoDomace formData={formData} setFormData={setFormData} />
            ) : formData.vrstaLica === VrstaLica.FIZICKO_STRANAC ? (
                <FizickoStranac formData={formData} setFormData={setFormData} />
            ) : (
                <RepublikaSrpska formData={formData} setFormData={setFormData} />
            )}
            <Row style={{ justifyContent: "flex-end", marginLeft: "-220px" }}>
                <KatakomButton label={"Сачувај"} onClick={handleSave} disabled={disabled} tooltipMessage={tooltipMessage} />
                <KatakomButton label={"Одустани"} onClick={handleClose} theme="secondary" style={{ marginLeft: "30px" }} />
            </Row>
        </ModalContent>
    );
};

export default AplicantsModalComponentTip2;
